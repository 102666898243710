import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SocialLinksComponent = () => {

  return (
      <SocialLinks>
        <h2>CONNECT</h2>
        <LinkList>
          <LinkItem>
            <LinkAnchor href="https://www.linkedin.com/in/shakhal" target="_blank" rel="noopener noreferrer">
              [LINKEDIN]
            </LinkAnchor>
          </LinkItem>
          <LinkItem>
            <LinkAnchor href="https://www.facebook.com/shakhal" target="_blank" rel="noopener noreferrer">
              [FACEBOOK]
            </LinkAnchor>
          </LinkItem>
          <LinkItem>
            <LinkAnchor href="https://wa.me/972528617450" target="_blank" rel="noopener noreferrer">
              [WHATSAPP]
            </LinkAnchor>
          </LinkItem>
          <LinkItem>
            <LinkAnchor href="https://github.com/shakhal?tab=repositories&q=&type=public&language=&sort=" target="_blank" rel="noopener noreferrer">
              [GITHUB]
            </LinkAnchor>
          </LinkItem>
        </LinkList>
      </SocialLinks>
  );
};

export default SocialLinksComponent;


const SocialLinks = styled.section`
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #33ff33;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
`;

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LinkItem = styled.li`
  margin: 10px 0;
  flex-basis: 48%;
`;

const LinkAnchor = styled.a`
  color: #33ff33;
  width: 115px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  border: 1px solid #33ff33;
  padding: 5px 10px;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #33ff33;
    color: #000000;
  }
`;