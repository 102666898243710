//App.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MapView from './MapView';
import './App.css';
import Loading from './Loading';
import SocialLinks from './SocialLinks';
import LastFmTracks from './LastFmTracks';
import axios from 'axios';

const calculateAge = () => {
  const birthDate = new Date('August 21, 1980');
  const currentDate = new Date();
  const ageInMilliseconds = currentDate - birthDate;
  const ageInYears = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25));
  return ageInYears;
}


const App = () => {
  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState(calculateAge());
  const [config, setConfig] = useState(null); 
  const [customText, setCustomText] = useState(null); 

  const fetchConfig = async () => {
      try {
          const response = await axios.get('/api/config');  
          setConfig(response.data);
          return response.data;
      } catch (error) {
        console.error('Error fetching config:', error);
      }
  }

  useEffect(() => {
    fetchConfig().then(config => {
      if (config.customText || config.maintenance ===  "true") {
        if (config.customText) {
          setCustomText(config.customText);
        }
      }
    });
  }, []);

  const handleLoadingComplete = () => {
    setLoading(false);
  };

  if (!config) {
    return null; // Or a loading indicator
  }

  if (loading) {
    // Pass the handleLoadingComplete function to Loading component
    return <Loading onComplete={handleLoadingComplete}   maintenance={config.maintenance} customText={config.customText} />;
  }

  // Main content of the app
  return (
    <Container className='container crt noselect'>
      <Header>
        <ProfileImage src="user.png" alt="Profile" />
        <ProfileInfo>
          <Name>SHAKHAL</Name>
          <Title>HUMAN, {age} </Title>
        </ProfileInfo>
      </Header>

      <Bio>
        <h2>ABOUT</h2>
        <p>
          Born on August 21, 1980, User emerged into the world just in time for the dawn of personal computing — probably thinking, "What's with all these blinking lights?" Equipped with an impressive set of opposable thumbs and a knack for solving complex problems, quickly became adept at typing furiously at a keyboard while simultaneously avoiding paper cuts.
        </p>
      </Bio>

      <MapSection>
        <MapView />
      </MapSection>
      <LastFmTracks />
      <SocialLinks/>
    </Container>
  );
};

export default App;

// Styled Components with Cryptic 80s PC Theme

const Container = styled.div`
  font-family: 'Courier New', Courier, monospace;
  color: #33ff33;
  background-color: #000000;
  padding: 12px;
  max-width: 900px;
  margin: 0 auto;
  border: 2px solid #33ff33;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 2px solid #33ff33;

  @media (max-width: 768px) {
    flex-direction: column; /* Stacks flex children vertically on small screens */
    align-items: center; /* Centers the items when stacked vertically */
  }
`;


const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px; /* Right margin on desktop */
  border: 2px solid #33ff33;

  @media (max-width: 768px) {
    margin-right: 0; /* Removes right margin on mobile */
    margin-bottom: 20px; /* Adds space below the image on mobile */
  }
`;


const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    .name, .title {
      font-size: 4vw; /* Example of adjusting font size based on viewport width */
    }
  }

`;

const Name = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #33ff33;
  margin-top: 5px;
`;

const Bio = styled.section`
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #33ff33;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #33ff33;
  }
`;

const MapSection = styled.section`
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #33ff33;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
`;