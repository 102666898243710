//Loading.js
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { printLines, typeLine } from './Printer';

const DELAY_BEFORE_WRITING = 1000; // Delay after the text is written
const DELAY_AFTER_WRITING = 1000; // Delay after the text is written

// The content to simulate ChatGPT "writing"
const loadingLines = [
  "Trace program: running",
  "Initializing engine...",
  // "Establishing secure connection...",
  // "Loading knowledge base...",
  // "Checking GPS signal...",
  // "Locating target...",
  // "Triangulating coordinates...",
  "Rendering visuals..."
]

const maintenanceLines = [
  "(\\(\\          ",
  "( -.-)         ",
  "o_(\")(\")      ",
  "",
  "Oops! ",
  "Our bunny is busy",
  "Tidying things up!",
  "Doing some spring cleaning 🧹",  
  "Digging a new burrow! 🐇",
  "Organizing carrots! 🥕🥕🥕🥕🥕🥕",
  "Collecting shiny pebbles 💎💎💎",
  "Knitting a cozy sweater 🧶",
  "Sharpening teeth! 🦷",
  "Taking a nap 💤",
  "Hopping around! 🐇",
  "Chasing butterflies! 🦋",
  "Playing hide and seek! 🙈",
  "Sniffing flowers! 🌸",
  "Polishing ears 🧼",
  "Try again later... 🕒",
]

const Loading = (props) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    // Determine which lines to print
    let lines;
    if (props.maintenance || props.customText) {
      lines = props.customText ? props.customText.split('\n') : maintenanceLines;
    } else {
      lines = loadingLines;
    }

    // Start printing lines
    printLines(lines, setDisplayText, DELAY_BEFORE_WRITING)
      .then(() => typeLine('\n', setDisplayText))
      .then(() => {
        if (props.onComplete && !props.maintenance) {
          setTimeout(() => props.onComplete(), DELAY_AFTER_WRITING); // Notify the parent component
        }
      });
  }, []);

  return (
    <LoadingContainer className='crt noselect'>
      <TypingArea>
        <Text>
          {displayText}
          <Cursor>■</Cursor>
        </Text>
      </TypingArea>
    </LoadingContainer>
  );
};

export default Loading;

// Blinking cursor animation (keyframes)
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// Styled components for the loading screen

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: #33ff33;
  font-family: 'Courier New', Courier, monospace;
`;

const TypingArea = styled.div`
  font-size: 18px;
  white-space: pre-wrap; // Ensures newlines and formatting are kept
  font-family: 'Courier New', Courier, monospace;
  width: 370px
`;

const Text = styled.span`
  font-size: 18px;
`;

const Cursor = styled.span`
  font-size: 18px;
  color: #33ff33;
  animation: ${blink} 0.3s infinite;
`;