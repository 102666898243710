const typingSpeed = 27;
const lineDelay = 600;


export const typeLine = async (line, setText) => {
    let charIndex = 0;

    while (charIndex < line.length) {
      await setText((prev) => prev + line[charIndex]);
      await sleep(typingSpeed);
      charIndex++;
    } 
};

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const printLines = async (lines, setText, initialDelay = 0) => {
  let lineIndex = 0;
  await sleep(initialDelay);
  while (lineIndex < lines.length) {
    await typeLine(lines[lineIndex], setText);
    lineIndex++;
    await sleep(lineDelay);
    if (lineIndex < lines.length) {
      setText((prev) => prev + '\n');
    }
  }
};