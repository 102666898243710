// LastFmTracks.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';

const LastFmTracks = () => {
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const response = await axios.get('/api/tracks');
        let tracks = response.data;
        setTracks(tracks);
      } catch (error) {
        console.error('Failed to fetch tracks', error);
      }
    };

    fetchTracks();
  }, []);

  return (
    <Frame>
      <Title>
        <h2>LAST.FM</h2>
      </Title>
      <TrackList>
        {tracks.map((track, index) => (
          <Track key={index} track={track} />
        ))}
      </TrackList>
    </Frame>
  );
};

export default LastFmTracks;

// Track component
const Track = ({ track }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleImageClick = () => {
    if (!isAnimating) {
      setIsAnimating(true);
    }
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const defualtImage = (e) => {
    e.target.src = "cover.png";
  };

  return (
    <TrackContainer>
      <TrackImage
        draggable="false"
        src={track.image[3]['#text']}
        alt={track.name}
        onClick={handleImageClick}
        onAnimationEnd={handleAnimationEnd}
        className={isAnimating ? 'animate' : ''}
        onError={(e) => defualtImage(e)}
      />
      <TrackName>- {track.name}</TrackName>
    </TrackContainer>
  );
};

// Styled components
const Title = styled.div`
  width: 100%;
`;

const Frame = styled.div`
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #33ff33;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
`;

const TrackList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
`;

const TrackContainer = styled.div`
  flex: 1 1 21%; /* Desktop: Approximately 5 tracks per row */
  margin-bottom: 10px;
  text-align: center;
  color: #fff;

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 10px); /* Mobile: Two tracks per row with space */
    margin: 5px; /* Adds space between tracks */
  }
`;

// Define keyframes for the filter animation
const filterAnimation = keyframes`
  0% {
    filter: grayscale(100%) brightness(50%) sepia(100%) hue-rotate(40deg) saturate(1000%);
  }
  20% {
    filter: grayscale(0%) brightness(100%) sepia(0%) hue-rotate(40deg) saturate(200%);
  }
  100% {
    filter: grayscale(100%) brightness(50%) sepia(100%) hue-rotate(40deg) saturate(1000%);
  }
`;

const TrackImage = styled.img`
  width: 100%;
  max-width: 95%;
  height: auto;
  user-drag: none;
  border-radius: 8px;
  filter: grayscale(100%) brightness(50%) sepia(100%) hue-rotate(40deg) saturate(1000%);
  border: 2px solid #33ff33;
  cursor: pointer; /* Change cursor to pointer on hover */

  &.animate {
    animation: ${filterAnimation} 1s forwards;
  }
`;

const TrackName = styled.p`
  font-family: 'Courier New', Courier, monospace;
  color: #33ff33;
`;
