import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import './MapView.css';
import { typeLine } from './Printer';
import { Mutex } from "async-mutex";

const mapStyles = { 
  height: '400px', 
  width: '100%', 
  borderRadius: '10px', 
  filter: 'grayscale(100%) brightness(0.6) contrast(1.5)', // Give it a greenish feel
  border: '2px solid #33ff33', 
  pointerEvents: 'none',
  filter: 'grayscale(100%) brightness(45%) sepia(90%) hue-rotate(80deg) saturate(300%)'
};

const mutex = new Mutex();
const MapView = () => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0, updated: 0 });
  const [lastUpdated, setLastUpdated] = useState('');
  const [lastFetched, setLastFetched] = useState(0);
  const [address, setAddress] = useState('');
    
  const fetchCoordinates = async () => {
    try {
      if (lastFetched && (new Date() - lastFetched < 30000)){
        return;
      }
      else{
        await setLastFetched(new Date());
        const response = await axios.get('/api/coordinates');  
        await setCoordinates({lat: response.data.latitude, lng: response.data.longitude, updated: response.data.updated});
        await updateAddress(response.data.address.formatted_address);
        await updateLastUpdated(response.data.updated);  
      } 
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  }

  useEffect(() => {
      if (mutex.isLocked()) return;
      mutex.acquire().then(function(release){
        fetchCoordinates();
        release();
      })

      const interval = setInterval(()=>fetchCoordinates(), 30000);
      return () => clearInterval(interval);
  }, []);

  const updateAddress = async (address) => {
    if (mutex.isLocked()) return;
    let release = await mutex.acquire();
    
    // if (address == coordinates.address) {
    //     return;
    // }
    setAddress('');
    await typeLine(address, setAddress);
    release();
  }

  const updateLastUpdated = async (updated) => {
    if (mutex.isLocked()) return;
    let release = await mutex.acquire();
    
    if (updated == coordinates.updated) {
        return;
    }
    setLastUpdated('');
    let text = `Last updated: ${updated} UTC`;
    await typeLine(text, setLastUpdated);
    release();
  }


  
  return (
    <div>
      {
      coordinates.updated != 0 ? 
        <div>
          <h2>LOCATION ACQUIRED</h2>
          <LoadScript googleMapsApiKey="AIzaSyDNQBHIu8nm5D05t_SsZAtcw1DlF8byN68">
            <div className='mapview'>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={coordinates}
              >
                <MarkerF position={coordinates} />
              </GoogleMap>
            </div>
          </LoadScript>
          <div>{address}</div>
          <div>{lastUpdated}</div>
        </div>
        : <h2>LOCATION NOT FOUND</h2>
      }
    </div>
    
  );
};

export default MapView;


